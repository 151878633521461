import React, { useState } from "react";
import Input from "./Input/Input";
import styles from "./LoginSignUp.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import CheckBox from "./Checkbox/CheckBox";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const SignUp = ({ Register, frontendInfo }) => {
  const navigate = useNavigate();
  const [rememberMe, setMemberMe] = useState(false);
  const { ref } = useParams();
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    passcode: "0000",

  });

  const LoginHandler = async (e) => {
    e.preventDefault();
    let url = "https://hosted-broker.bigrouting.io";
    let body = {
      email: values.email,
      password: values.password,
    };

    let { data, status } = await axios.post(url + "/register", body, {
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": localStorage.getItem("tenant_id"),
        Authorization: "Bearer " + Cookies.get("auth-token"),
      },
    });
    if (status === 200) {
      Cookies.set("auth-token", data.token);
      navigate("/dashboard");
    } else {
      console.log(data);
    }
  };


  const inputs = [
    {
      label: "Email Address",
      type: "email",
      name: "email",
      placeholder: "Enter your email address",
    },
    {
      label: "First Name ",
      type: "text",
      name: "firstname",
      placeholder: "Enter your first name",
    },
    {
      label: "Last Name ",
      type: "text",
      name: "lastname",
      placeholder: "Enter your last name",
    },
    {
      label: "User Name",
      type: "text",
      name: "username",
      placeholder: "Enter your username",
    },

    {
      label: "Password ",
      type: "password",
      name: "password",
      placeholder: "Enter your password",
    },
    // {
    //   label: "Passcode ",
    //   type: "password",
    //   name: "passcode",
    //   placeholder: "Security Pin",
    // },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>Create an Account</h2>
            <p className={styles.text}>
              {/* Create Account to get access to our services. */}
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div
              className={[
                styles.rememberOrForgot,
                styles.termsAndCondition,
              ].join(" ")}
            >
              <CheckBox
                label="I agree to the "
                checked={rememberMe}
                setChecked={setMemberMe}
              />{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={styles.termsAndCondition}
              >
                Terms & Condition
              </a>
            </div>

            {" "}
            <button className={styles.button} onClick={(e) => {
              Register(e, values);
            }}>
              Sign Up
            </button>
          </form>
          <div className={styles.suggetionContainer}>
            <p className={styles.suggestionText}>Already have an account?</p>
            {"  "}
            <Link to="/login" className={styles.toggle}>
              &nbsp; Log in instead
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};

export default SignUp;
