import React, { useEffect, useState } from "react";
import styles from "./InvestmentAccount.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const InvestmentAccount = ({ setTransferFunds, userInfo }) => {
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  // balance is stored in backend raised to pow of 8
  // so we divide by 10^8 to get the actual balance

  let balance = userInfo?.balance;


  const [positions, setPositions] = useState(null);
  async function get_positions() {
    let api = "https://hosted-broker.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    try {
      let { data, status } = await axios.get(api + "/positions", {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });

      if (status != 200) {
        return;
      }


      console.log(data)
      setPositions(data);

    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        // window.location.href = "/login";
      }
    }
  }

  useEffect(() => {
    get_positions()
  }, []);


  return (
    <div className={styles.investmentAccount}>
      <h4 className={styles.title}>Balance </h4>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.availableAmount}>
            {balance} <span className={styles.currency}>USD</span>{" "}
          </h4>
          <p className={styles.text}>Available Funds</p>
        </div>
        <div>
          <h4 className={styles.lockedAmount}>{positions?.filter((a) => a.status.toLowerCase() == "active")
            .reduce((acc, curr) => acc + curr.capital, 0) / Math.pow(10, 8)}</h4>
          <p className={styles.text}>Currently Invested</p>
        </div>
      </div>
      <button className={styles.button} onClick={
        () => {
          window.location.href = "/plans"
        }
      }>
        View Plans
      </button>
    </div>
  );
};

export default InvestmentAccount;
