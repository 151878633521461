import React from "react";
import DataTable from "../../Component/InvestmentHistory/DataTable/DataTable";
import { TxTable } from "../../Component/InvestmentHistory/DataTable/DataTable";
import BackButton from "../../Component/BackButton/BackButton";
import Heading from "../../Component/InvestmentHistory/Heading/Heading";
import { TxHeading } from "../../Component/InvestmentHistory/Heading/Heading";
import styles from "./InvestmentHistoryPage.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const InvestmentHistoryPage = ({ frontendInfo }) => {
  const [investData, setInvestData] = useState({});
  async function getInvestments() {
    let api = "https://hosted-broker.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    try {
      let { data, status } = await axios.get(api + "/positions", {
        headers: {
          "Content-Type": "application/json",
          "x-tenant-id": localStorage.getItem("tenant_id"),
          Authorization: "Bearer " + user_id,
        },
      });
      setInvestData(data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        // window.location.href = "/login";
      }
    }




  }

  useEffect(() => {
    getInvestments();
  }
    , []);
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Home" />
        <Heading />
      </div>
      <DataTable investData={investData} />
    </div>
  );
};

export const DepositHistoryPage = () => {
  const [txData, setTxData] = useState({});
  async function getInvestments() {
    let api = "https://hosted-broker.bigrouting.io";
    let user_id = Cookies.get("auth-token");
    let { data } = await axios.get(api + "/user/get-transactions/", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    console.log(data);
    setTxData(data);

  }

  useEffect(() => {
    getInvestments();
  }
    , []);
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Home" />
        <TxHeading />
      </div>
      <TxTable txData={txData} />
    </div>
  );
};

export default InvestmentHistoryPage;
